'use client'

export function ChevronRight(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="chevron-right">
        <path
          id="Icon"
          d="M8.69995 13.5999L12.3 9.9999L8.69995 6.3999"
          stroke="#09090A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...props}
        />
      </g>
    </svg>
  )
}
