'use client'

import Image from 'next/image'
import Link from 'next/link'

import logoCaseStatusDark from '../images/logos/casestatus-dark.svg'
import logoCaseStatusLight from '../images/logos/casestatus-light.svg'
import logoCaseStatusNoTextDark from '../images/logos/casestatus-logo-dark.svg'
import logoCaseStatusNoTextLight from '../images/logos/casestatus-logo-light.svg'

import logoStatsigDark from '../images/logos/statsig-dark.svg'
import logoStatsigLight from '../images/logos/statsig-light.svg'
import logoStatsigNoTextDark from '../images/logos/statsig-logo-dark.svg'
import logoStatsigNoTextLight from '../images/logos/statsig-logo-light.svg'

import logoDagsterDark from '../images/logos/dagster-dark.svg'
import logoDagsterLight from '../images/logos/dagster-light.svg'
import logoDagsterNoTextDark from '../images/logos/dagster-logo-dark.svg'
import logoDagsterNoTextLight from '../images/logos/dagster-logo-light.svg'

import logoDigSouthDark from '../images/logos/digsouth-dark.svg'
import logoDigSouthLight from '../images/logos/digsouth-light.svg'
import logoDigSouthNoTextDark from '../images/logos/digsouth-logo-dark.svg'
import logoDigSouthNoTextLight from '../images/logos/digsouth-logo-light.svg'

import logoDenoDark from '../images/logos/deno-dark.svg'
import logoDenoLight from '../images/logos/deno-light.svg'
import logoDenoNoTextDark from '../images/logos/deno-logo-dark.svg'
import logoDenoNoTextLight from '../images/logos/deno-logo-light.svg'

import logoEvidenceDark from '../images/logos/evidence-dark.svg'
import logoEvidenceLight from '../images/logos/evidence-light.svg'
import logoEvidenceNoTextDark from '../images/logos/evidence-logo-dark.svg'
import logoEvidenceNoTextLight from '../images/logos/evidence-logo-light.svg'

import logoModalDark from '../images/logos/modal-dark.svg'
import logoModalLight from '../images/logos/modal-light.svg'
import logoModalNoTextDark from '../images/logos/modal-logo-dark.svg'
import logoModalNoTextLight from '../images/logos/modal-logo-light.svg'

import logoHyperDark from '../images/logos/hyper-dark.svg'
import logoHyperLight from '../images/logos/hyper-light.svg'
import logoHyperNoTextDark from '../images/logos/hyper-logo-dark.svg'
import logoHyperNoTextLight from '../images/logos/hyper-logo-light.svg'

import stars from '../images/background/logo-cloud-stars.svg'
import React from 'react'
// const Spline = React.lazy(() => import('@splinetool/react-spline'));

const COMPANIES = [
  [
    {
      name: 'Statsig',
      href: 'https://www.statsig.com/',
      logos: {
        logoLight: logoStatsigLight,
        logoDark: logoStatsigDark,
        logoLightNoText: logoStatsigNoTextLight,
        logoDarkNoText: logoStatsigNoTextDark,
      },
    },
    {
      name: 'Deno',
      href: 'https://deno.com/',
      logos: {
        logoLight: logoDenoLight,
        logoDark: logoDenoDark,
        logoLightNoText: logoDenoNoTextLight,
        logoDarkNoText: logoDenoNoTextDark,
      },
    },
    {
      name: 'Modal',
      href: 'https://modal.com/',
      logos: {
        logoLight: logoModalLight,
        logoDark: logoModalDark,
        logoLightNoText: logoModalNoTextLight,
        logoDarkNoText: logoModalNoTextDark,
      },
    },
    {
      name: 'Hyper',
      href: 'https://hyper.io/',
      logos: {
        logoLight: logoHyperLight,
        logoDark: logoHyperDark,
        logoLightNoText: logoHyperNoTextLight,
        logoDarkNoText: logoHyperNoTextDark,
      },
    },
  ],
  [
    {
      name: 'Evidence',
      href: 'https://evidence.dev/',
      logos: {
        logoLight: logoEvidenceLight,
        logoDark: logoEvidenceDark,
        logoLightNoText: logoEvidenceNoTextLight,
        logoDarkNoText: logoEvidenceNoTextDark,
      },
    },
    {
      name: 'Dagster',
      href: 'https://dagster.io/',
      logos: {
        logoLight: logoDagsterLight,
        logoDark: logoDagsterDark,
        logoLightNoText: logoDagsterNoTextLight,
        logoDarkNoText: logoDagsterNoTextDark,
      },
    },
    {
      name: 'CaseStatus',
      href: 'https://www.casestatus.com/',
      logos: {
        logoLight: logoCaseStatusLight,
        logoDark: logoCaseStatusDark,
        logoLightNoText: logoCaseStatusNoTextLight,
        logoDarkNoText: logoCaseStatusNoTextDark,
      },
    },
    {
      name: 'DigSouth',
      href: 'https://digsouth.com/',
      logos: {
        logoLight: logoDigSouthLight,
        logoDark: logoDigSouthDark,
        logoLightNoText: logoDigSouthNoTextLight,
        logoDarkNoText: logoDigSouthNoTextDark,
      },
    },
  ],
]

export function LogoCloud() {
  return (
    <>
      <div className="col-span-12 grid grid-cols-12 relative">
        <Image src={stars} alt="stars" className="logo-cloud-stars" />
        <div aria-hidden className="full-width-line" />
        <div className="logo-cloud-row col-start-1 col-span-12 grid grid-cols-10 lg:grid-cols-12">
          <div className="col-start-1 lg:col-start-2 col-span-1" />
          {COMPANIES[0].map((company, idx, arr) => (
            <Link
              key={company.name}
              className={`group logo-cloud-item border-left border-right py-6 sm:py-8 lg:p-11 border-white col-span-2 flex justify-center items-center`}
              href={company.href}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image
                className="hidden lg:block group-hover:hidden"
                src={company.logos.logoDark}
                alt={company.name}
              />
              <Image
                className="hidden lg:group-hover:block"
                src={company.logos.logoLight}
                alt={company.name}
              />
              <Image
                className="group-hover:hidden lg:hidden"
                src={company.logos.logoDarkNoText}
                alt={company.name}
              />
              <Image
                className="hidden max-lg:group-hover:block lg:hidden"
                src={company.logos.logoLightNoText}
                alt={company.name}
              />
            </Link>
          ))}
          <div className="col-start-12 lg:col-start-11 col-span-1" />
        </div>
        <div aria-hidden className="full-width-line" />
      </div>
      <div className="col-start-2 lg:col-start-3 col-span-10 lg:col-span-8 used-by-text px-8 py-12 md:p-16 text-3xl md:text-4xl text-center flex items-center justify-center">
        <div className="max-w-xl text-center">
          Used by teams at Statsig, Deno, Dagster, Evidence and more.
        </div>
      </div>
      <div className="col-span-12 grid grid-cols-12">
        <div aria-hidden className="full-width-line" />
        <div className="logo-cloud-row col-start-1 col-span-12 grid grid-cols-10 lg:grid-cols-12">
          <div className="col-start-1 lg:col-start-2 col-span-1" />
          {COMPANIES[1].map((company, idx, arr) => (
            <Link
              key={company.name}
              className={`group logo-cloud-item 
              border-left border-right 
              py-6 sm:py-8 lg:p-11 border-white col-span-2 flex justify-center items-center`}
              href={company.href}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image
                className="hidden lg:block group-hover:hidden"
                src={company.logos.logoDark}
                alt={company.name}
              />
              <Image
                className="hidden lg:group-hover:block"
                src={company.logos.logoLight}
                alt={company.name}
              />
              <Image
                className="group-hover:hidden lg:hidden"
                src={company.logos.logoDarkNoText}
                alt={company.name}
              />
              <Image
                className="hidden max-lg:group-hover:block lg:hidden"
                src={company.logos.logoLightNoText}
                alt={company.name}
              />
            </Link>
          ))}
          <div className="col-start-12 lg:col-start-11 col-span-1" />
        </div>
        <div aria-hidden className="full-width-line" />
      </div>
    </>
  )
}
