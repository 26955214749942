'use client'

import React from 'react'
import Image from 'next/image'

import logoCaseStatusDark from '../images/logos/casestatus-logo-dark.svg'
import logoCaseStatusLight from '../images/logos/casestatus-logo-light.svg'
import logoCitibotDark from '../images/logos/citibot-logo-dark.svg'
import logoCitibotLight from '../images/logos/citibot-logo-light.svg'
import logoDagsterDark from '../images/logos/dagster-logo-dark.svg'
import logoDagsterLight from '../images/logos/dagster-logo-light.svg'
import logoHyperDark from '../images/logos/hyper-logo-dark.svg'
import logoHyperLight from '../images/logos/hyper-logo-light.svg'
import logoQuipliDark from '../images/logos/quipli-logo-dark.svg'
import logoQuipliLight from '../images/logos/quipli-logo-light.svg'
import logoStatsigDark from '../images/logos/statsig-logo-dark.svg'
import logoStatsigLight from '../images/logos/statsig-logo-light.svg'

import headshotStatsig from '../images/headshots/vijaye.jpeg'
import headshotHyper from '../images/headshots/tyler.jpeg'
import headshotCitibot from '../images/headshots/bratton.jpeg'
import headshotCaseStatus from '../images/headshots/charles-lane.jpeg'
import headshotQuipli from '../images/headshots/kyle-clements.jpeg'
import headshotDagster from '../images/headshots/pedram.jpeg'

const QUOTES = [
  {
    name: 'Vijaye Raji',
    company: 'Statsig',
    logo: { dark: logoStatsigDark, light: logoStatsigLight },
    title: 'CEO of Statsig',
    avatar: headshotStatsig,
    quote:
      '"Scout is an indispensable tool for our Engineering, DS and Sales teams to engage with our customers.  We rely on Scout daily, making it an integral part of our operations."',
  },
  {
    name: 'Pedram Navid',
    company: 'Dagster',
    logo: { dark: logoDagsterDark, light: logoDagsterLight },
    title: 'Head of Data Engineering at Dagster',
    avatar: headshotDagster,
    quote:
      '"Scout has made it easy for us to scale out our open-source support, while still maintaining a very high level of quality in responses."',
  },
  {
    name: 'Tyler Hall',
    company: 'Hyper',
    logo: { dark: logoHyperDark, light: logoHyperLight },
    title: 'Principal Software Engineer at Hyper',
    avatar: headshotHyper,
    quote:
      '"Setting up an AI-powered chat experience, tailored to a specific customer\'s data and content, used to take days. Now with Scout, it takes minutes."',
  },
  {
    name: 'Bratton Riley',
    company: 'Citibot',
    logo: { dark: logoCitibotDark, light: logoCitibotLight },
    title: 'CEO of Citibot',
    avatar: headshotCitibot,
    quote:
      '"The Scout GenAI system has helped transform how we can improve customer service for all our government customers."',
  },
  {
    name: 'Charles Lane',
    company: 'Case Status',
    logo: { dark: logoCaseStatusDark, light: logoCaseStatusLight },
    title: 'CTO of Case Status',
    avatar: headshotCaseStatus,
    quote:
      '"Scout allowed us to test our concept on day one and worked with us to find a long term solution that scaled with our business."',
  },
  {
    name: 'Kyle Clements',
    company: 'Quipli',
    logo: { dark: logoQuipliDark, light: logoQuipliLight },
    title: 'Founder & CEO of Quipli',
    avatar: headshotQuipli,
    quote:
      '"My experience with Scout has been transformative, particularly in accelerating our AI features\' time to market with its seamless integration and exceptional support."',
  },
]

export function Testimonials() {
  const [activeQuote, setActiveQuote] = React.useState(QUOTES[0])

  const handleClick = (company: string) => {
    const foundQuote = QUOTES.find((v) => v.company === company)
    if (foundQuote) setActiveQuote(foundQuote)
  }

  return (
    <>
      <div aria-hidden className="full-width-line" />
      <div className="testimonials col-span-12 relative py-30">
        <div aria-hidden className="full-width-line" />
        <div className="grid grid-cols-10 lg:grid-cols-12 col-span-12">
          <div className="col-span-12 md:col-start-2 md:col-span-8 lg:col-start-2 lg:col-span-10 flex w-full justify-center flex-col lg:flex-row">
            {/* column 1 - blanks*/}
            <div className="hidden lg:flex flex-col w-full max-w-[80px]">
              <div className="shrink w-full h-[141.83px] border-bottom" />
              <div className="shrink w-full h-[141.83px] border-bottom" />
              <div className="shrink w-full h-[141.83px]" />
            </div>
            {/* column 2 - logos */}
            <div className="flex lg:flex-col w-full lg:max-w-[142.33px]">
              {QUOTES.slice(0, 3).map((quote, idx, arr) => (
                <div
                  key={quote.company}
                  className={`logo ${
                    activeQuote.company === quote.company ? 'active' : ''
                  } w-full h-[141.83px]  border-right border-bottom ${
                    idx === 0 ? 'border-left' : 'lg:border-left'
                  } ${idx === arr.length - 1 ? 'lg:border-b-0' : ''}`}
                >
                  <button
                    className="w-full h-full flex justify-center items-center"
                    onClick={() => handleClick(quote.company)}
                  >
                    <Image
                      alt={`${quote.company} logo`}
                      src={
                        activeQuote.company === quote.company
                          ? quote.logo.light
                          : quote.logo.dark
                      }
                    />
                  </button>
                </div>
              ))}
            </div>
            {/* column 3 - active quote */}
            <div className="bg-stars grow px-8 sm:px-12 py-10 lg:py-8 max-lg:border-left max-lg:border-right max-lg:border-bottom">
              <div className="max-w-[470px] flex flex-col items-center justify-center mx-auto h-full">
                <p
                  className={`quote text-3xl ${
                    activeQuote.quote.length < 167
                      ? 'xl:text-4xl'
                      : 'xl:text-3.5xl'
                  } pb-8`}
                >
                  {activeQuote.quote}
                </p>
                <div className="flex flex-col items-center">
                  <div className="rounded-full h-10 w-10 overflow-hidden mb-3">
                    <Image alt="headshot" src={activeQuote.avatar} />
                  </div>
                  <div className="name mb-1">{activeQuote.name}</div>
                  <div className="position">{activeQuote.title}</div>
                </div>
              </div>
            </div>
            {/* column 4 - logos */}
            <div className="flex lg:flex-col w-full lg:max-w-[142.33px]">
              {QUOTES.slice(3).map((quote, idx, arr) => (
                <div
                  key={quote.company}
                  className={`logo ${
                    activeQuote.company === quote.company ? 'active' : ''
                  } w-full h-[141.83px] border-right ${
                    idx === 0 ? 'border-left' : 'lg:border-left'
                  } ${
                    idx === arr.length - 1
                      ? 'lg:border-b-0'
                      : 'lg:border-bottom'
                  }`}
                >
                  <button
                    className="w-full h-full flex justify-center items-center"
                    onClick={() => handleClick(quote.company)}
                  >
                    <Image
                      alt={`${quote.company} logo`}
                      src={
                        activeQuote.company === quote.company
                          ? quote.logo.light
                          : quote.logo.dark
                      }
                    />
                  </button>
                </div>
              ))}
            </div>
            {/* column 5 - blanks*/}
            <div className="hidden lg:flex flex-col w-full max-w-[80px]">
              <div className="shrink w-full h-[141.83px] border-bottom" />
              <div className="shrink w-full h-[141.83px] border-bottom" />
              <div className="shrink w-full h-[141.83px]" />
            </div>
          </div>
        </div>
        <div aria-hidden className="full-width-line" />
      </div>
    </>
  )
}
