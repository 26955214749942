'use client'

import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { Logo } from './Logo'
import slackLogo from '../images/logos/slack-dark.svg'
import linkedInLogo from '../images/logos/linkedin.svg'
import xLogo from '../images/logos/x.svg'

import statusGreen from '../images/status-all-green.svg'
import statusYellow from '../images/status-all-yellow.svg'
import statusRed from '../images/status-all-red.svg'

export function Footer() {
  const [uptimeStatus, setUptimeStatus] = React.useState(null)

  React.useEffect(() => {
    async function getStatus() {
      const res = await fetch(`/api/uptime/1408343`)
      const { status } = await res.json()
      setUptimeStatus(status)
    }

    getStatus()
  }, [])

  return (
    <>
      <div aria-hidden className="full-width-line" />
      <footer className="col-span-12 pt-20 sm:pt-0 md:pt-20 pb-[170px] px-6 sm:px-10 lg:px-0 grid grid-cols-12">
        <div className="col-start-1 col-span-12 lg:col-start-2 lg:col-span-10 lg:px-24">
          <div className="flex flex-col md:flex-row md:justify-between text-white">
            <div className="hidden md:block footer-bg-vector" />
            <div className="relative flex flex-col sm:flex-row md:flex-col sm:items-center md:items-start isolate grow">
              <Link href="/">
                <Logo />
              </Link>
              <div className="-ml-[4.5rem] sm:ml-0 md:-ml-[4.5rem] md:mt-8 ">
                {uptimeStatus && (
                  <Link
                    href="https://status.scoutos.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={getStatusImg(uptimeStatus)} alt="status" />
                  </Link>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-y-8 md:gap-y-0 sm:flex-row sm:gap-x-20 md:gap-x-12 xl:gap-x-[125px] isolate pb-[68px]">
              <div>
                <h4 className="pb-6 opacity-60 uppercase text-[13px] font-medium leading-4 tracking-[0.65px]">
                  Links
                </h4>
                <ul>
                  <li>
                    <Link href="#features">Features</Link>
                  </li>
                  <li>
                    <Link href="#pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link
                      href="https://docs.scoutos.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Docs
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://blog.scoutos.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="pb-6 opacity-60 uppercase text-[13px] font-medium leading-4 tracking-[0.65px]">
                  Solutions
                </h4>
                <ul>
                  <li>Slack Bot</li>
                  <li>Discord Bot</li>
                  <li>Command</li>
                  <li>Onsite Chat</li>
                </ul>
              </div>
              <div>
                <h4 className="pb-6 opacity-60 uppercase text-[13px] font-medium leading-4 tracking-[0.65px]">
                  Legal
                </h4>
                <ul>
                  <li>
                    <Link href="/terms-of-use">Terms of Service</Link>
                  </li>
                  <li>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div aria-hidden className="full-width-line" />
          <div className="flex flex-col sm:flex-row justify-between text-white">
            <p className="text-base text-white opacity-60 py-5">
              Copyright &copy; {new Date().getFullYear()} Scout. All rights
              reserved.
            </p>
            <div aria-hidden className="sm:hidden full-width-line" />
            <div className="flex items-stretch isolate">
              <div className="w-1/3 border-left border-right py-5 sm:w-14 mx-auto flex justify-center items-center">
                <Link
                  href="https://scoutos.com/slack"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="mx-auto"
                    src={slackLogo}
                    alt="slack logo"
                    aria-label="Connect with us on Slack"
                  />
                </Link>
              </div>
              <div className="w-1/3 border-right py-5 sm:w-14 mx-auto flex justify-center items-center">
                <Link
                  href="https://twitter.com/scoutshq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="mx-auto"
                    src={xLogo}
                    alt="x logo"
                    aria-label="Connect with us on X"
                  />
                </Link>
              </div>
              <div className="w-1/3 border-right py-5 sm:w-14 mx-auto flex justify-center items-center">
                <Link
                  href="https://www.linkedin.com/company/scoutos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="mx-auto"
                    src={linkedInLogo}
                    alt="LinkedIn logo"
                    aria-label="Connect with us on LinkedIn"
                  />
                </Link>
              </div>
            </div>
            <div aria-hidden className="sm:hidden full-width-line" />
          </div>
          <div aria-hidden className="hidden sm:block full-width-line" />
        </div>
      </footer>
    </>
  )
}

const getStatusImg = (status) => {
  switch (status) {
    case 'up':
      return statusGreen
    case 'maintenance':
      return statusYellow
    case 'down':
      return statusRed
    default:
      return statusGreen
  }
}
