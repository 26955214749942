'use client'

import clsx from 'clsx'
import Link from 'next/link'
import Image from 'next/image'

import bgPricing from '../images/background/pricing-bg.svg'
import heartIcon from '../images/heart.svg'
import starsIcon from '../images/stars.svg'
import buildingIcon from '../images/building.svg'
import plusIcon from '../images/plus.svg'
import stars from '../images/background/bg-pricing-stars.svg'
import bgMostPopular from '../images/background/bg-pricing-most-pop.svg'

function CheckIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      className={clsx('flex-none fill-none stroke-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M12.5 8.76286L8.75 13.3462L7.08333 11.6795M17.5 10.8462C17.5 14.9883 14.1421 18.3462 10 18.3462C5.85786 18.3462 2.5 14.9883 2.5 10.8462C2.5 6.70406 5.85786 3.34619 10 3.34619C14.1421 3.34619 17.5 6.70406 17.5 10.8462Z"
        stroke="#434343"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function Plan({
  name,
  price,
  description,
  href,
  features,
  featured = false,
  icon,
}) {
  const id = name.toLowerCase()
  return (
    <section
      id={id}
      className={clsx(
        'plan flex flex-col rounded-2xl p-6 sm:py-8 sm:px-6 relative',
        featured ? 'order-first lg:order-none featured' : '',
      )}
    >
      {featured && (
        <Image
          className="absolute -top-[15%] left-0 right-0 mx-auto isolate"
          src={bgMostPopular}
          alt="background image"
        />
      )}
      <div className={`${featured ? 'block' : 'hidden'}`}>
        <div
          id="most-popular"
          className={`${
            featured ? 'inline-flex' : 'hidden'
          } absolute -top-[16px] left-0 right-0 max-w-fit mx-auto w-fit`}
        >
          <span>Most Popular</span>
        </div>
      </div>
      <div id={`plan-name-${id}`} className="plan-name mb-4">
        <Image src={icon} alt="icon" />
        <h3 className="font-display text-lg text-white">{name}</h3>
      </div>
      <p className="text-4xl font-medium tracking-[-1px] text-white leading-[110%]">
        {price}
      </p>
      <p
        className={clsx(
          'mt-2 text-base font-normal leading-[130%] text-white opacity-50',
        )}
      >
        {description}
      </p>

      <ul
        role="list"
        className={clsx(
          'order-last flex flex-col gap-y-3 text-sm',
          featured ? 'text-white' : 'text-slate-200',
        )}
      >
        {features.map((feature) => (
          <li key={feature} className="flex items-center">
            <CheckIcon className={featured ? 'h-7 w-7' : 'h-6 w-6'} />
            <span className="ml-3">{feature}</span>
          </li>
        ))}
      </ul>
      <Link
        href={href}
        className={`sign-up ${featured ? 'featured' : ''} `}
        aria-label={`Get started with the ${name} plan for ${price}`}
      >
        {name === 'Enterprise' ? 'Request Access' : 'Get Access'}
      </Link>
    </section>
  )
}

export function Pricing() {
  return (
    <>
      <div aria-hidden className="full-width-line" />
      <section
        // id="pricing"
        aria-label="Pricing"
        className="py-20 sm:py-30 col-start-1 col-span-12 grid grid-cols-12 px-6 md:px-0 relative"
      >
        <Image className="bg-pricing-stars" alt="pricing stars" src={stars} />
        <Image
          className="hidden lg:block bg-pricing"
          src={bgPricing}
          alt="background"
        />
        <div
          id="pricing"
          className="px-0 col-start-1 md:col-start-2 col-span-12 md:col-span-10 lg:col-start-3 lg:col-span-8 mx-auto"
        >
          <div className="md:text-center">
            <h2 className="text-3xl tracking-[-2px] text-white sm:text-4xl font-medium leading-9">
              <span className="relative whitespace-nowrap">
                A plan for every need.
              </span>
            </h2>
            <p className="mt-4 text-xl text-[#828386] font-normal leading-[26px] isolate">
              Whether you are just starting or require massive scale, we have a
              solution.
            </p>
          </div>
          <div className="mt-14 grid max-w-2xl grid-cols-1 gap-y-6 xl:gap-y-0 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-3 xl:mx-0 xl:gap-x-5">
            <Plan
              name="Hobby"
              price="$0"
              description="Great for personal use or a first step to explore the Scout platform."
              href="https://tally.so/r/m6LrkJ"
              features={[
                '100 Interactions (GPT-3.5 Only)',
                'Deploy 1 App',
                'Connect 1 Collection',
                '10GB of Storage',
                'Community Support (Discord)',
              ]}
              icon={heartIcon}
            />
            <Plan
              featured
              name="Pro"
              price="$50"
              description="Perfect for building and scaling models with limited context."
              href="https://tally.so/r/m6LrkJ"
              features={[
                'Unlimited Interactions',
                'Deploy 10 Apps',
                'Connect 10 Collections',
                '1TB of Storage',
                'Community & Email Support',
              ]}
              icon={starsIcon}
            />
            <Plan
              name="Enterprise"
              price="Custom"
              description="For large scale models with large and ever-changing context."
              href="https://tally.so/r/m6LrkJ"
              features={[
                'Unlimited Interactions',
                'Custom Interaction Pricing',
                'Unlimited Apps',
                'Unlimited Collections',
                'Unlimited Storage',
                'Dedicated Support',
              ]}
              icon={buildingIcon}
            />
          </div>
          <div className="py-5 sm:mx-auto lg:-mx-8 xl:mx-0 max-w-2xl lg:max-w-none ">
            <div className="dedicated-support flex flex-col lg:flex-row isolate">
              <div className="lg:flex lg:w-2/3">
                <div className="px-5 py-7 lg:flex-auto">
                  <h3 className="text-[28px] font-medium tracking-[-1px] text-white leading-[110%]">
                    Dedicated Support
                  </h3>
                  <p className="mt-3 text-base leading-5 text-white opacity-50">
                    We are here to help get you started with a dedicated support
                    engineer who can assist with scoping your first models and
                    getting them deployed.
                  </p>
                  <div className="flex items-center gap-x-4">
                    <h4 className="my-5 flex-none text-[13px] tracking-[0.65px] font-medium leading-4 text-white opacity-80 uppercase">
                      What’s included
                    </h4>
                  </div>
                  <ul
                    role="list"
                    className="grid grid-cols-1 gap-4 text-base leading-5 text-white opacity-80 md:grid-cols-2 md:gap-2"
                  >
                    <li className="flex items-center gap-x-3">
                      <CheckIcon className="h-6 w-6" />
                      Shared Slack Channel
                    </li>
                    <li className="flex items-center gap-x-3">
                      <CheckIcon className="h-6 w-6" />
                      Dedicated Support Engineer
                    </li>
                    <li className="flex items-center gap-x-3">
                      <CheckIcon className="h-6 w-6" />
                      Prompt Engineering Guidance
                    </li>
                    <li className="flex items-center gap-x-3">
                      <CheckIcon className="h-6 w-6" />
                      Context Sourcing Guidance
                    </li>
                  </ul>
                </div>
              </div>
              <div className="request-box w-full lg:w-1/3 items-stretch">
                <div className="text-center lg:flex lg:flex-col lg:justify-center">
                  <div className="mx-auto w-full py-7 px-5 ">
                    <div className="add-on flex p-[3px] pr-[11px] w-fit mx-auto items-center gap-[6px] rounded-2xl border border-white border-opacity-50">
                      <Image src={plusIcon} alt="icon" className="h-5 w-5" />
                      <h3 className="text-[13px] text-[#cecece] uppercase tracking-[0.65px] leading-4 font-medium">
                        Add On
                      </h3>
                    </div>
                    <p className="mt-3 flex flex-col justify-center gap-y-1">
                      <span className="text-4xl font-medium tracking-[-1px] text-white leading-10">
                        $750
                      </span>
                      <span className="text-base leading-5 text-white opacity-80">
                        monthly
                      </span>
                    </p>
                    <a
                      href="https://tally.so/r/m6LrkJ"
                      className="btn_2 mt-6 block w-full"
                    >
                      Request Access
                    </a>
                    <p className="mt-3 text-xs leading-4 text-white opacity-50">
                      No long term contract obligation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
