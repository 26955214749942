'use client'

import Link from 'next/link'
import { Container } from './Container'
import Image from 'next/image'
import React from 'react'
import { Logo } from './Logo.jsx'
import { ChevronRight } from './ChevronRight.jsx'
import apps from '../images/screenshots/apps-dark.svg'
import noiseAndTexturePng from '../images/background/noise-texture.png'
import stars from '../images/background/stars-hero.svg'
import greenTriangle from '../images/background/rectangle-20.svg'
import pinkTriangle from '../images/background/rectangle-21-2.svg'
import ellipse2 from '../images/background/ellipse-2.svg'

export function Hero() {
  return (
    <div className="hero relative col-span-12">
      <div className="bg-hero">
        <Image
          className="noise-texture"
          src={noiseAndTexturePng}
          alt="noise texture"
          placeholder="blur"
          style={{
            maxWidth: '100vw',
          }}
        />
        <Image
          className="triangle-green-hero"
          src={greenTriangle}
          alt="green triangle"
        />
        <Image
          className="triangle-pink-hero"
          alt="pink triangle"
          src={pinkTriangle}
        />
        <Image className="ellipse-2" alt="ellipse 2" src={ellipse2} />
        <div className="ellipse-3" />
        <Image className="stars" src={stars} alt="stars" />
        <div className="ellipse-4" />
      </div>
      <div className="hidden lg:block vertical-line-l" />
      <div className="hidden lg:grid vector-container absolute col-span-12 grid-cols-12 h-full w-full">
        <div className="relative col-start-3 col-span-8 overflow-x-hidden w-full">
          <BackgroundVectors />
        </div>
      </div>
      <Container className="bg-transparent pt-5 lg:pt-3 col-span-12 z-10">
        <div className="text-left">
          <div className="grid grid-cols-4 pb-24">
            <div className="col-span-4 lg:col-span-4 z-10 pt-10 lg:pt-20 mx-auto">
              <span className="flex justify-center">
                <Link href="https://docs.scoutos.com/">
                  <button className="beta-btn flex justify-center items-center">
                    <Logo
                      className="w-auto inline-block"
                      width="auto"
                      height={12}
                    />{' '}
                    <span
                      style={{
                        marginLeft: '4px',
                      }}
                    >
                      is currently in beta
                      <ChevronRight
                        style={{ display: 'inline-block' }}
                        outline="white"
                        stroke="white"
                      />
                    </span>
                  </button>
                </Link>
              </span>
              <h1 className="hero-h1 max-w-4xl bg-gradient-to-br text-center pt-2 sm:pb-2 leading-18 sm:leading-98%">
                AI for teams building
              </h1>
              <h1 className="hero-h1 max-w-4xl bg-gradient-to-br text-center pb-2 sm:pt-2 leading-18 sm:leading-98%">
                what&apos;s next.
              </h1>
              <p className="hero-p mt-6 max-w-xl mx-auto">
                Scout gives you the tools to build custom agents and workflows
                in record time.
              </p>
              <div className="mt-10 flex justify-center">
                <Link className="btn_2" href="https://tally.so/r/m6LrkJ">
                  Request a Demo{' '}
                  <ChevronRight style={{ display: 'inline-block' }} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="col-start-1 col-span-12 mb-28 grid grid-cols-12">
        <div aria-hidden className="full-width-line" />
        <Image
          src={apps}
          alt="screenshot"
          className="col-start-2 col-span-10 lg:col-start-3 lg:col-span-8 mx-auto"
        />
        <div aria-hidden className="full-width-line" />
      </div>
      <div className="hidden lg:block vertical-line-r" />
    </div>
  )
}

const BackgroundVectors = () => {
  return (
    <div className="hidden lg:block vectors">
      <svg
        width="1024"
        height="568"
        viewBox="0 0 1024 568"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_201_5003)">
          <path
            d="M756.474 631.189L755.603 631.185L756.039 631.939L1047.74 1137.06C1087.2 1205.39 1063.78 1292.77 995.434 1332.22C927.089 1371.67 839.691 1348.26 800.231 1279.92L519.49 793.795L519.057 793.046L518.624 793.795L235.135 1284.7C195.674 1353.04 108.277 1376.45 39.9286 1337C-28.4199 1297.54 -51.8374 1210.17 -12.3769 1141.84L283.294 629.841L283.725 629.095L282.863 629.091L-301.233 626.504C-380.153 626.155 -443.848 561.908 -443.499 483.006C-443.149 404.099 -378.887 340.428 -299.966 340.771L258.44 343.246L259.311 343.25L258.875 342.496L-12.3769 -127.212C-51.8375 -195.549 -28.42 -282.917 39.9286 -322.377C108.277 -361.827 195.673 -338.416 235.135 -270.078L518.624 220.829L519.057 221.578L519.49 220.829L800.231 -265.299C839.691 -333.637 927.089 -357.048 995.434 -317.598C1063.78 -278.138 1087.2 -190.77 1047.74 -122.433L777.922 344.8L777.491 345.546L778.353 345.55L1325.23 347.977C1404.15 348.32 1467.84 412.569 1467.5 491.471C1467.15 570.374 1402.88 634.054 1323.96 633.704L756.474 631.189Z"
            stroke="url(#paint0_linear_201_5003)"
            strokeOpacity="0.2"
          />
          <path
            d="M756.474 631.189L755.603 631.185L756.039 631.939L1047.74 1137.06C1087.2 1205.39 1063.78 1292.77 995.434 1332.22C927.089 1371.67 839.691 1348.26 800.231 1279.92L519.49 793.795L519.057 793.046L518.624 793.795L235.135 1284.7C195.674 1353.04 108.277 1376.45 39.9286 1337C-28.4199 1297.54 -51.8374 1210.17 -12.3769 1141.84L283.294 629.841L283.725 629.095L282.863 629.091L-301.233 626.504C-380.153 626.155 -443.848 561.908 -443.499 483.006C-443.149 404.099 -378.887 340.428 -299.966 340.771L258.44 343.246L259.311 343.25L258.875 342.496L-12.3769 -127.212C-51.8375 -195.549 -28.42 -282.917 39.9286 -322.377C108.277 -361.827 195.673 -338.416 235.135 -270.078L518.624 220.829L519.057 221.578L519.49 220.829L800.231 -265.299C839.691 -333.637 927.089 -357.048 995.434 -317.598C1063.78 -278.138 1087.2 -190.77 1047.74 -122.433L777.922 344.8L777.491 345.546L778.353 345.55L1325.23 347.977C1404.15 348.32 1467.84 412.569 1467.5 491.471C1467.15 570.374 1402.88 634.054 1323.96 633.704L756.474 631.189Z"
            stroke="url(#paint1_angular_201_5003)"
            strokeOpacity="0.6"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_201_5003"
            x1="457.234"
            y1="-899.307"
            x2="446.665"
            y2="884.906"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <radialGradient
            id="paint1_angular_201_5003"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1071.19 725.413) rotate(-32.8557) scale(658.806 585.311)"
          >
            <stop offset="0.105218" stopColor="white" />
            <stop offset="0.312923" stopColor="white" stopOpacity="0.0103403" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <clipPath id="clip0_201_5003">
            <rect
              width="1024"
              height="870"
              fill="white"
              transform="translate(0 -302)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
